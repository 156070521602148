import React from "react"
import Playground from "@agney/playground"
import "@reach/tabs/styles.css"

const theme = {
  container: {
    borderColor: "white",
    minHeight: "20em",
    minWidth: "15em",
    display: "flex",
  },
  error: {
    background: "",
    color: "",
  },
  console: {},
  divider: {
    width: 2,
    background: "",
  },
  editor: {
    fontFamily: `Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
    backgroundColor: ``,
    color: ``,
  },
  tabs: {
    tabHeader: {
      borderBottom: "",
      panelBackground: "",
      background: "",
      color: "",
    },
    tabPanel: {
      phoneHeight: "10em",
    },
    selectedTab: {
      background: "",
      borderBottom: "0.2em solid rgb(224,37,35)",
    },
  },
}

export const Demo = ({ snippet }) => {
  return (
    <section className=" max-w-screen grid justify-items-center">
      <Playground
        id="example"
        initialSnippet={snippet}
        defaultEditorTab="javascript"
        transformJs
        theme={theme}
      />
    </section>
  )
}
