import React from "react"

export const Footer = () => {
  return (
    <section className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
        <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
          <h1 className="title-font font-medium text-2xl text-white">
            A tech newsletter that teaches you something new
          </h1>
          <p className="prose text-light text-xl text-gray-400 leading-relaxed mt-4">
            This blog was created to document my own learning, and share useful
            tips with other software engineers.
          </p>
          <p className="prose text-light text-xl text-gray-400 leading-relaxed mt-4">
            My newsletter is like that, but straight to your inbox! It contains
            useful links I've found around the web, <em>sneak peeks</em> of my
            new articles, and access to free resources I've created.
          </p>
        </div>
        <div className="lg:w-2/6 md:w-1/2 bg-gray-800 bg-opacity-50 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 motion-safe:animate-wiggle">
          <form
            action="https://app.convertkit.com/forms/1382555/subscriptions"
            method="POST"
            target="_blank"
          >
            <h2 className="text-white text-2xl font-light title-font mb-5">
              Sign Up
            </h2>
            <div className="relative mb-4">
              <label
                htmlFor="full-name"
                className="leading-7 text-sm text-gray-400"
              >
                Name
              </label>
              <input
                type="text"
                id="full-name"
                name="fields[first_name]"
                required
                className="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-white rounded border border-gray-600 focus:border-white text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-400"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email_address"
                required
                className="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-white rounded border border-gray-600 focus:border-white text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <button className="text-white bg-red-500 border-0 w-full py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg">
              Subscribe
            </button>
            <p className="text-xs mt-3 text-center">
              You can unsubscribe whenever you'd like, and I probably hate spam
              even more than you do.
            </p>
          </form>
        </div>
      </div>
    </section>
  )
}
