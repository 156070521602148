import { MDXProvider } from "@mdx-js/react"
import React from "react"
import { Nav } from "./nav"
import { Demo } from "./playground"
import Bio from "./bio"
import { Footer } from "./footer"

const shortcodes = { Bio, Demo }

export default function Layout({ children }) {
  return (
    <>
      <section className="flex flex-col h-screen justify-between bg-gray-900 w-screen">
        <Nav />
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
        <Footer />
      </section>
    </>
  )
}
